import React from 'react'
import {StaticQuery, Link, graphql} from 'gatsby'


export default function NewsArchiveNav() {
    const renderNav = (data) => {
        const years = data.sort((a, b)=>{return b-a})
        return years.map(year => 
        <>
          <li>
            <Link to={`/news/archive/${year}`} activeClassName='current' partiallyActive={true}>{year}</Link>
          </li>
         {years.indexOf(year)+1 === years.length? null:<li>|</li>}
        </>
      )
    } 

    return (
        <StaticQuery
      query={graphql`
        query archivemenu{
            allContentfulNewsArchive {
              edges {
                  node {
                  slug
                  year
                  }
              }
            }
        }
      `}
      render={data => (
        <div className='arc-cont'>
           <ul className='archive-nav-cont'>
               {renderNav(data.allContentfulNewsArchive.edges.map(year => parseInt(year.node.year)))}
            </ul> 
        </div>
      )}
    />
    )
}
