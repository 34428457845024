import React from 'react'
import { graphql, Link } from 'gatsby'
// import { Helmet } from 'react-helmet'
// import get from 'lodash/get'
import Layout from '../components/layout'
import SEO from "../components/seo"
// import NewsNavBar from '../components/newsNavBar'
import NewsArchiveNav from '../components/newsArchiveNav'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from '@contentful/rich-text-types'
import './exhibition-archive-year.css'
//make its own style sheet for archive years

class NewsArchiveYearTemplate extends React.Component {
  render() {
    const options = {
      renderNode: {
        [INLINES.ASSET_HYPERLINK]: (node, children) => {
          return <a href={node.data.target.file.url} className="pdf-link" target='_blank'  rel="noreferrer">{children}</a>
        },
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
          return <Link href={`/exhibitions/${node.data.target.slug}`} >{children}</Link>
        },
        [INLINES.HYPERLINK]: (node, children) => {
          return <a href={node.data.uri} target='_blank'>{children}</a>
        }
      }
    }
        const {year} = this.props.data.contentfulNewsArchive
        const news = this.props.data.contentfulNewsArchive.news ===null? <h1>Coming Soon</h1>: renderRichText(this.props.data.contentfulNewsArchive.news, options)
        // const slug = this.props.data.contentfulNewsArchive.slug
        //send slug to NewsNavbar to dynamically create path in order for styles to be activated when in the correct path
    return (
      <Layout location={this.props.location}>
          {/* <NewsNavBar slug={slug}/> */}
          <SEO 
          title={`${year} News`}
          description={`Archive of news and press for ${year}`}
          />
            <h1 className="visibility-hidden">{ `News ${this.props.data.contentfulNewsArchive.year}`}</h1>
            <NewsArchiveNav />
                <div className="exhibitions-cont">{news}</div>
      </Layout>
    )
  }
}

export default NewsArchiveYearTemplate

export const pageQuery = graphql
`
query archiveyear($slug: Date!){
    contentfulNewsArchive(slug: {eq: $slug}) {
      slug
      year
      news {
        raw
        references {
          ... on ContentfulExhibition {
            # contentful_id is required to resolve the references
            contentful_id
            title
            slug
          }
          ... on ContentfulAsset {
            contentful_id
            __typename
            file {
              url
              contentType
            }
          }
        }    
      }
    }
  }
`
